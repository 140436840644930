import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

const BlogPage = ({ data }) => {
    return (
        <Layout pageTitle="Blog Posts">
                {
                    data.allMdx.nodes.map(node => (
                        <article key={node.id}>
                            <h2 className='mt-4 text-xl font-medium'>
                                <Link to={`/blog/${node.slug}`}>
                                {node.frontmatter.title}</Link></h2>
                            <p className='text-gray-400 text-sm'>{node.frontmatter.date}</p>
                        </article>
                    ))
                }
        </Layout >
    )
}

export const query = graphql`
query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    nodes {
    frontmatter {
    date(formatString: "MMM D, YYYY")
      title
    }
      id
      slug
    }
}
    }
    `

export default BlogPage